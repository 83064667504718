import { ExtensionSlot } from 'piral-core';
import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import borderlayout from './borderlayout';
import emptylayout from "./emptylayout"
import singlecomponentlayout from './singlecomponentlayout';

export default (layout) => {

  let parsedLayout = null;
  switch (layout) {
    case 'empty':
      console.info("empty layout");
      parsedLayout = emptylayout.Layout;
      break;
    case 'border':
      console.info("border layout");
      parsedLayout = borderlayout.Layout;
      break;
    case 'single':
      console.info("single layout");
      parsedLayout = singlecomponentlayout.Layout;
      break;
    default:
      console.info("no default layout found, parsing the layout from the config: ", layout);
      parseHtmlLayout(layout);
      parsedLayout = () => <div>{parseHtmlLayout(layout)}</div>;
  }

  return {
    Layout: parsedLayout
  }
}

function iterateChildren() {

}

function parseHtmlLayout(layout) {
  console.info("parseHtmlLayout", layout);

  // layout = `"<div type= 'Flex' label='Flex' rootParentType='Flex' style="display:flex">
  // <div minHeight='400' minWidth='400' style='width:400px'><Region regionKey='west' key='west' name='west' minHeight='400' minWidth='400' restriction='explorer'></Region></div>
  // <div minHeight='400' minWidth='600' flex='1'  style='width:600px'><Region regionKey='east' key='east' name='east' minHeight='400' minWidth='600' flex='1' restriction='modeller'></div>
  // </Region></div>"`;
  let modifiedHtml = layout.replace(/<div\s+type=(['"])flex\1/gi, "<div style='display:flex;' type=$1flex$1");
  modifiedHtml = modifiedHtml.replace(/<div\s+type= (['"])flex\1/gi, "<div style='display:flex;' type=$1flex$1");



  return ReactHTMLParser(modifiedHtml, {
    transform: ({ type, name, attribs, children }, index) => {
      if (type === "tag" && (name === "extensionslot" || name === "region")) {
        console.info("extensionslot found ", attribs);
        if (!attribs.key) {
          attribs.key = "extensionslot_" + index;
        }
        attribs.style = {
          width: attribs.width + 'px',
          minWidth: attribs.width + 'px',
          height: attribs.height + 'px',
          flex: attribs.flex
        };
        console.info("attribs: ", attribs);
        return React.createElement(ExtensionSlot, attribs, children);
      }
    }
  });
}