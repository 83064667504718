export default class FunctionManager {
  constructor() {
    this.functions = {};
  }

  add(key, fn) {
    if (typeof (fn) !== "function") {
      console.error("Added non-function to FunctionManager with key " + key);
    }
    this.functions[key] = fn;
  }

  get(key) {
    return this.functions[key];
  }
}