import { AwsRum } from 'aws-rum-web';

// https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
const rumConfig = {
  sessionSampleRate: 1,
  guestRoleArn: "arn:aws:iam::576031260401:role/RUM-Monitor-eu-central-1-576031260401-2489372795861-Unauth",
  identityPoolId: "eu-central-1:608b0b15-2e2a-4846-bbef-54c0f8c58f6b",
  endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
  telemetries: [['errors', { stackTraceLength: 500 }],
    'performance',
  ['http', { stackTraceLength: 500, addXRayTraceIdHeader: true }]
  ],
  allowCookies: true,
  enableXRay: true
};

const APPLICATION_ID = '4fe03869-405f-4dd0-94b7-47f6a4b7f296';
const APPLICATION_VERSION = '1.0.0';
const APPLICATION_REGION = 'eu-central-1';

class RUMProvider {
  constructor() {
    this.monitoring = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      rumConfig
    );
  }

  enable() {
    this.monitoring.enable();
  }

  disable() {
    this.monitoring.disable();
  }

  recordPageView(pageView) {
    this.monitoring.recordPageView(pageView);
  }

  recordError(error) {
    this.monitoring.recordError(error);
  }

  recordEvent(event) {
    this.monitoring.recordEvent(event);
  }

  getInternalMonitoring() {
    return this.monitoring;
  }
}

export default function createMonitoring() {
  const monitoring = new RUMProvider();
  return monitoring;
};